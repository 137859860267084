export const getQueryParams = () => {
  const {
    location: { search },
  } = window;
  if (search) {
    const params = search.replace(/^\?/, '').split('&');
    return params.reduce((acc, param) => {
      const [key, value] = param.split('=');
      return {
        ...acc,
        [key]: value,
      };
    }, {});
  }
  return {};
};

import styles from './ToolTip.module.css';
import { JSX, VNode } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import clsx from 'clsx';

interface TooltipProps {
  message: VNode | string;
  children: VNode;
  className?: string;
  messageClass?: string;
}

const OFFSET_PADDING = 5;

const ToolTip = ({ children, message, className, messageClass, ...props }: TooltipProps) => {
  const messageRef = useRef<HTMLDivElement>();
  const rootRef = useRef<HTMLDivElement>();
  const [isShown, setIsShown] = useState(false);
  const [offset, setOffset] = useState({ left: 0, top: 0 });

  useEffect(() => {
    if (messageRef.current && rootRef.current) {
      const { width: messageWidth, height: messageHeight } = messageRef.current.getBoundingClientRect();
      const { width: contentWidth } = rootRef.current.getBoundingClientRect();
      setOffset({ top: -messageHeight - OFFSET_PADDING, left: contentWidth / 2 - messageWidth / 2 });
    }
  }, [messageRef]);

  const handleMouseOver = () => {
    setIsShown(true);
  };
  const handleMouseOut = () => {
    setIsShown(false);
  };

  return (
    <span
      {...props}
      className={clsx(className, styles.llTooltip)}
      ref={rootRef}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div
        className={clsx(styles.llTooltipMessage, messageClass, {
          [styles.llTooltipShown]: isShown,
        })}
        ref={messageRef}
        style={offset}
      >
        {message}
      </div>
      {children}
    </span>
  );
};

export default ToolTip;

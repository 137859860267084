export const parseDate = (value: string): number | null => {
  const date = Date.parse(value);
  return isNaN(date) ? null : date;
};

export const formatDate = (value: string | number): string | null => {
  const date = new Date(value);
  return date instanceof Date
    ? date.toLocaleDateString('default', {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
      })
    : null;
};

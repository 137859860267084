import { Theme } from 'api/types';
import { WidgetProps } from 'components/types';
import { default as V1 } from 'components/v1/Widget/Widget';
import { default as V2 } from 'components/v2/Widget/Widget';
import { default as V3 } from 'components/v3/Widget/Widget';

type VersionedWidgetProps = WidgetProps & {
  version: string;
};

const VersionedWidget = ({ version, ...props }: VersionedWidgetProps) => {
  switch (version) {
    case 3:
      return <V3 {...props} />;
    case 2:
      return <V2 {...props} />;
    default:
      return <V1 {...props} />;
  }
};

export default VersionedWidget;

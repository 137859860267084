import { Product, ProductResponse, SkuResponse, TestResponse } from './types';
import { request } from './request';
import { camelizeKeys } from 'utils/formatters';
import { config } from 'utils/config';

export const getExampleProductData = () =>
  ({
    header: 'Test Results Certified by 3rd Party Lab',
    description: 'Every batch is tested by an ISO 17025 certified lab to verify active ingredients and product purity',
    contaminants: [
      { name: 'Lead', value: '0.23 ppb' },
      { name: 'Arsenic', value: '<0.0000008 ppb' },
      { name: 'Mercury', value: '0.12 ppb' },
      { name: 'Cadmium', value: '<0.08 ppb' },
    ],
    bigBars: [
      { name: 'Gelatin', claim: 10.1, actual: 10.2, units: 'g' },
      { name: 'Creatine', claim: 4.5, actual: 4.5, units: 'g' },
    ],
    lastTestDate: new Date().toISOString(),
    skus: [
      {
        id: '1',
        shopifyVariantId: '1',
        name: 'Sku 1',
        contaminants: [
          { name: 'Mercury', value: '0.12 ppb' },
          { name: 'Cadmium', value: '<0.08 ppb' },
        ],
        bigBars: [{ name: 'Gelatin', claim: 10.1, actual: 10.2, units: 'g' }],
      },
      {
        id: '2',
        shopifyVariantId: '2',
        name: 'Sku 2',
        contaminants: [
          { name: 'Mercury', value: '0.12 ppb' },
          { name: 'Cadmium', value: '<0.08 ppb' },
          { name: 'Cadmium', value: '<0.08 ppb' },
        ],
        bigBars: [
          { name: 'Gelatin', claim: 10.1, actual: 10.2, units: 'g' },
          { name: 'Power', claim: 10.1, actual: 10.2, units: 'g' },
        ],
      },
    ],
  }) as Product;

export const getExampleTestResult = () =>
  ({
    tests: [
      {
        id: 85,
        worksheet_data: {
          lead_loq: {
            value: 1.5,
          },
          lead_mass: {
            value: 1.25,
          },
          lead_limit: {
            value: 2,
          },
          arsenic_loq: {
            value: 1.25,
          },
          cadmium_loq: {
            value: 2.5,
          },
          mercury_loq: {
            value: 1.25,
          },
          arsenic_mass: {
            value: 1.15,
          },
          cadmium_mass: {
            value: 1.45,
          },
          mercury_mass: {
            value: 1.35,
          },
          arsenic_limit: {
            value: 2,
          },
          cadmium_limit: {
            value: 2,
          },
          mercury_limit: {
            value: 2,
          },
        },
        complete_date: null,
        created_at: '2024-07-11T19:09:12.366Z',
        updated_at: '2024-07-24T21:47:42.907Z',
        sample: {
          id: 34,
          lot: 1,
          sku: {
            id: 2,
            name: 'ABC2',
            code: null,
            product: {
              id: 1,
              name: 'Beef Bone Broth',
            },
          },
        },
      },
    ],
  }) as TestResponse;

export const ProductAPI = {
  getProduct: async (companyId: string): Promise<ProductResponse> => {
    return request({ url: `${config.apiUrl}/api/companies/${companyId}/embed_configurations` });
  },

  getSkus: async (companyId: string): Promise<SkuResponse> => {
    return request({ url: `${config.apiUrl}/api/companies/${companyId}/skus` });
  },

  getTestResults: async (productId: string): Promise<TestResponse> => {
    // return request({url: `${config.apiUrl}/api/companies/${companyId}/lots?lot=${lotNumber}`});
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(camelizeKeys(getExampleTestResult()));
      }, 1000);
    });
  },
};

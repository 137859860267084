export function chunk(array: any[], size: number): any[][] {
  return array.reduce((acc, value, index) => {
    if (index % size === 0) {
      acc[acc.length] = [value];
    } else {
      acc[acc.length - 1] = [...acc[acc.length - 1], value];
    }
    return acc;
  }, []);
}

import clsx from 'clsx';
import styles from './ThemedLayout.module.css';
import { VNode } from 'preact';

const ThemedLayout = ({
  className,
  children,
  style,
  ...props
}: {
  className?: string;
  style?: any;
  children: VNode;
}) => {
  return (
    <div className={clsx('ll-themed-layout', styles.llThemedLayout, className)} style={style} {...props}>
      {children}
    </div>
  );
};

export default ThemedLayout;

import { ProductSku } from 'api/types';
import DropDown from 'components/DropDown/DropDown';
import { WidgetProps } from 'components/types';
import useConfig from 'hooks/useConfig';
import { useState } from 'preact/hooks';
import CircleExclamation from '../../Icons/CircleExclamation';
import ToolTip from '../../ToolTip/ToolTip';
import Active from '../../v2/Active/Active';
import Contaminants from '../../v2/Contaminants/Contaminants';
import LiveTestingIndicator from '../../v2/LiveTestingIndicator/LiveTestingIndicator';
import '../../v2/variables.css';
import styles from './Widget.module.css';

const Widget = ({ product, style, hideActiveIngredients, hideContaminants, ...props }: WidgetProps) => {
  const { config } = useConfig();
  const { variant } = config;
  const { lastTestDate, skus = [] as ProductSku[] } = product;
  const [selectedSkuId, setSelectedSkuId] = useState<string | undefined>(skus?.[0]?.id);

  // Has a variant been defined by the page
  const hasVariant = variant && skus.some(({ shopifyVariantId }) => shopifyVariantId === variant);

  // Only look at variant data
  const { bigBars = [], contaminants = [] } =
    skus.find(({ id, shopifyVariantId }) => (hasVariant ? shopifyVariantId === variant : id === selectedSkuId)) ?? {};

  if (skus.length === 0) {
    // If we lack data to show, don't render anything
    return;
  }

  return (
    <div className={styles.llWidgetV3} style={style} {...props}>
      <div className={styles.llWidgetHeader}>
        Test Results Certified by 3rd Party Lab <LiveTestingIndicator lastTestDate={lastTestDate} />
      </div>
      <div>
        This product <span className={styles.llSafeLabel}>has passed</span> strict quality, safety, and regulatory
        standards.
      </div>
      {!hasVariant && (
        <DropDown
          onSelect={setSelectedSkuId}
          selectedId={selectedSkuId}
          options={skus.map(({ id, name }) => ({ id: id, label: name }))}
        />
      )}

      {!hideActiveIngredients && !!bigBars?.length && <Active bars={bigBars} />}

      <div className={styles.llTestingCompliance}>
        Rigorously tested to ensure product purity and compliance
        <ToolTip
          message="Units are parts per billion (ppb) or micrograms (μg). Testing standards are from California's Prop 65."
          className={styles.llTestingIcon}
        >
          <CircleExclamation />
        </ToolTip>
      </div>
      {!hideContaminants && !!contaminants?.length && <Contaminants contaminants={contaminants} />}
    </div>
  );
};

export default Widget;

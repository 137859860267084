import styles from './DropDown.module.css';
import { useEffect, useRef, useState } from 'preact/hooks';
import clsx from 'clsx';
import DropdownArrow from '../Icons/DropdownArrow';

export type DropDownOption = {
  id: string;
  label: string;
};

export type DropDownProps = {
  name?: string;
  selectedId?: string;
  options?: DropDownOption[];
  onSelect?: (id?: string) => void;
};

const DropDown = (props: DropDownProps) => {
  const { selectedId, name, options, onSelect } = props;
  const [searchOpen, setSearchOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const getSelectedLabel = (value: string): string => {
    const selectedItem = options.find(({ id }) => value === id);
    return selectedItem?.label ?? '';
  };

  const handleOpenSelect = () => {
    setSearchOpen(true);
  };
  const handleCloseSelect = () => {
    setSearchOpen(false);
  };
  const handleSelect = (id: string) => {
    onSelect?.(id);
    handleCloseSelect();
  };

  useEffect(() => {
    const closeMenu = (event: MouseEvent) => {
      if (!containerRef.current || !containerRef.current.contains(event.target as Node)) {
        handleCloseSelect();
      }
    };
    window.addEventListener('click', closeMenu);
    return () => window.removeEventListener('click', closeMenu);
  }, [containerRef]);

  return (
    <div className={styles.dropdown} ref={containerRef}>
      <div className={styles.selectedLabel} tabIndex={0} onClick={handleOpenSelect}>
        <span className={styles.labelValues}>{getSelectedLabel(selectedId)}</span>
        <DropdownArrow />
      </div>
      <div className={styles.menuAnchor}>
        <div className={clsx(styles.menu, { [styles.open]: searchOpen })}>
          <div>
            {options.map(({ label, id }, index) => {
              return (
                <div
                  className={clsx(styles.menuItem, { [styles.selectedItem]: selectedId === id })}
                  onClick={() => handleSelect(id)}
                >
                  {label}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropDown;
